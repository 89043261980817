import React, { useState } from 'react';
import styled from 'styled-components';
import { useGlobal } from 'reactn';
import classNames from 'classnames';
import moment from 'moment';
import { Empty } from 'antd';
import TimesheetGridRow from './TimesheetGridRow';
import Button from '../../common/components/Button';
import TimesheetGridRowAddNew from './TimesheetGridRowAddNew';
import Tag from '../../common/components/Tag';
import Tooltip from '../../common/components/Tooltip';
import { GET_AVAILABLE_DELIVERABLES } from '../queries';
import client from '../../../api/graphql/client';
import { useQuery } from '@apollo/react-hooks';
import ProjectBudgetIndicator from './ProjectBudgetIndicator';
import ProjectMilestoneBudgetIndicator from './ProjectMilestoneBudgetIndicator';
import { constructOptions } from './TimesheetGridRowAddNew';
import TaskSelector from '../../common/components/TaskSelector';
import { GET_USER_TIMESHEET } from '../queries';
import { ADD_TIMESHEET_LINEITEM } from '../mutations';
import { getWeekDays } from '../../common';

const Table = styled.table`
  width: 100%;
  table-layout: fixed;
`;
const Header = styled.thead``;
const HeaderRow = styled.tr``;
const HeaderCol = styled.th`
  padding: 1em;
  border-bottom: 1px solid #c5c5c5;
  ${props => props.showTopBorder && 'border-top: 1px dashed #c5c5c5;'};
  ${props => props.showRightBorder && 'border-right: 1px dashed #c5c5c5;'};
  ${props => props.showLeftBorder && 'border-left: 1px dashed #c5c5c5;'};
  text-align: ${props => (props.align ? props.align : 'initial')};
  width: ${props => props.width};
  &:first-child {
    padding-left: 0;
  }
`;

const Day = styled.div`
  display: flex;
  justify-content: center;
`;

const ProjectNameRow = styled.tr`
  border-top: 1px solid #f1f1f1;
  &:first-child {
    border-top: none;
  }
  > td {
    padding: 1rem 0 0.5rem 0;
    font-weight: bold;
  }
`;

// use lowercase 3 char week day
const sumTotalsForWeekDay = (weekDay, lineItems) =>
  lineItems.reduce((acc, cur) => {
    const weekDayTask = cur[`${weekDay}Task`];
    if (cur.deliverable.ignoreHoursInTotal) {
      return acc;
    }

    if (weekDayTask) {
      return acc + weekDayTask.hours;
    }

    return acc;
  }, 0);

export const constructDailyTotals = lineItems => ({
  mon: sumTotalsForWeekDay('mon', lineItems),
  tue: sumTotalsForWeekDay('tue', lineItems),
  wed: sumTotalsForWeekDay('wed', lineItems),
  thu: sumTotalsForWeekDay('thu', lineItems),
  fri: sumTotalsForWeekDay('fri', lineItems),
  sat: sumTotalsForWeekDay('sat', lineItems),
  sun: sumTotalsForWeekDay('sun', lineItems),
});

const getClassName = (projectId, collapsedProjectIds) => {
  return classNames('transition-opacity duration-300', {
    'opacity-0 h-0 block overflow-hidden': collapsedProjectIds.includes(projectId),
    'opacity-100': !collapsedProjectIds.includes(projectId),
  });
};

const TimesheetGrid = ({ isLocked, lineItems, timesheetId, endDate }) => {
  const [user] = useGlobal('user');
  const [collapsedProjectIds, setCollapsedProjectIds] = useState([]);
  const useNewTaskSelector = user?.settings?.useNewTaskSelector;
  const { loading: loadingDeliverables, data } = useQuery(GET_AVAILABLE_DELIVERABLES, {
    variables: { user: user._id, endDate },
  });

  const projects = lineItems
    .reduce((acc, cur) => {
      if (acc.find(p => p._id === cur.project._id)) {
        return acc;
      }
      return [
        ...acc,
        {
          ...cur.project,
        },
      ];
    }, [])
    .sort((a, b) => a.name.localeCompare(b.name));

  const projectsWithTasks = projects.map(p => {
    return {
      ...p,
      lineItems: lineItems
        // lineItemIndex is used to determine which item in the timesheet lineItem array to remove.
        // since we are splitting lineItems into project groups, we have to carry over original index in the original lineItems array
        .map((i, index) => ({ ...i, lineItemIndex: index }))
        .filter(i => i.project._id === p._id),
    };
  });

  // if (lineItems.length === 0) {
  //   return <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />;
  // }

  const handleClick = async item => {
    const deliverableId = item[item.length - 1];
    const projectId = item[0];
    await client.mutate({
      mutation: ADD_TIMESHEET_LINEITEM,
      awaitRefetchQueries: true,
      refetchQueries: [
        {
          query: GET_USER_TIMESHEET,
          variables: {
            endDate: endDate,
            user: user._id,
          },
        },
      ],
      variables: {
        deliverableId,
        timesheetId,
        projectId,
        user: user._id,
      },
    });
  };

  const selectedDeliverableIds = lineItems.map(li => li.deliverable._id);
  const weekDays = getWeekDays(endDate);

  return (
    <Table>
      <Header>
        <HeaderRow>
          <HeaderCol width="initial">Tasks</HeaderCol>
          <HeaderCol width="50px" />
          {weekDays.map((day, index) => {
            let observedHoliday = null;
            const currentDate = moment(endDate).subtract(6 - index, 'days');
            const displayedDay = currentDate.format('ddd D');
            const holiday = null;
            const isWeekend = currentDate.day() === 6 || currentDate.day() === 0;

            // const holiday = currentDate.isHoliday();
            // if (day === 'Fri') {
            //   observedHoliday = currentDate
            //     .clone()
            //     .add(1, 'days')
            //     .isHoliday();
            // }
            // if (day === 'Mon') {
            //   observedHoliday = currentDate
            //     .clone()
            //     .subtract(1, 'days')
            //     .isHoliday();
            // }

            // TODO handle bug with moment holdiday. Remove in future.
            if (currentDate.format('YYYYMMDD') === '20191122') {
              return (
                <HeaderCol key={day} width="80px" align="center">
                  <Day>{displayedDay}</Day>
                </HeaderCol>
              );
            }
            if (currentDate.format('YYYYMMDD') === '20191129') {
              return (
                <HeaderCol key={day} width="80px" align="center">
                  <Day>
                    <Tooltip title="Day after Thanksgiving">
                      <Tag color="blue">{displayedDay}</Tag>
                    </Tooltip>
                  </Day>
                </HeaderCol>
              );
            }

            return (
              <HeaderCol
                key={day}
                width="80px"
                align="center"
                showTopBorder={isWeekend}
                showRightBorder={currentDate.day() === 0}
                showLeftBorder={currentDate.day() === 6}
                className={classNames('', { 'bg-gray-100': isWeekend })}
              >
                <Day
                  className={classNames({
                    'font-bold': !isWeekend,
                  })}
                >
                  {observedHoliday || holiday ? (
                    <Tooltip title={observedHoliday ? `${observedHoliday} (observed)` : holiday}>
                      <Tag color={observedHoliday ? 'purple' : 'blue'}>{displayedDay}</Tag>
                    </Tooltip>
                  ) : (
                    displayedDay
                  )}
                </Day>
              </HeaderCol>
            );
          })}
          {!isLocked && <HeaderCol width="80px" align="center" />}
        </HeaderRow>
      </Header>
      <tbody>
        {lineItems?.length > 0 ? (
          projectsWithTasks.map(p => {
            const milestones = p.lineItems.reduce((acc, cur) => {
              if (acc.find(item => item.name === cur.deliverable.milestoneName)) {
                return acc;
              }
              return [
                ...acc,
                { deliverableId: cur.deliverable._id, name: cur.deliverable.milestoneName },
              ];
            }, []);
            const projectsLineItems = p.lineItems.filter(li => li.project._id === p._id);
            return (
              <React.Fragment key={p._id}>
                <ProjectNameRow>
                  <td colSpan={9}>
                    <div className="flex flex-row gap-2 items-center">
                      {p.projectCode && <div className="font-bold">[{p.projectCode}]</div>}
                      <div className="font-bold">{p.name}</div>
                      <Tooltip title="Collapse/Expand project tasks">
                        <Button
                          icon={collapsedProjectIds.includes(p._id) ? 'down' : 'up'}
                          size="small"
                          onClick={() =>
                            setCollapsedProjectIds(
                              collapsedProjectIds.includes(p._id)
                                ? collapsedProjectIds.filter(id => id !== p._id)
                                : [...collapsedProjectIds, p._id],
                            )
                          }
                        ></Button>
                      </Tooltip>
                      {!loadingDeliverables && useNewTaskSelector && !isLocked && (
                        <TaskSelector
                          compact
                          type="primary"
                          options={constructOptions(data?.projects, selectedDeliverableIds)}
                          onSelect={selected =>
                            handleClick([selected.project, undefined, selected.task])
                          }
                          defaultSelected={{
                            project: p._id,
                          }}
                        />
                      )}
                      {!isLocked && (
                        <ProjectBudgetIndicator projectId={p._id} lineItems={projectsLineItems} />
                      )}
                    </div>
                  </td>
                </ProjectNameRow>

                {milestones
                  .sort((a, b) => {
                    const nameA = a.name;
                    const nameB = b.name;
                    if (nameA < nameB) {
                      return -1;
                    }
                    if (nameA > nameB) {
                      return 1;
                    }
                    return 0;
                  })
                  .map(milestone => {
                    const lineItems = p?.lineItems.filter(li => {
                      return li.deliverable.milestoneName === milestone.name;
                    });
                    return (
                      <React.Fragment key={milestone.deliverableId}>
                        <tr className={getClassName(p._id, collapsedProjectIds)}>
                          <td colSpan={9}>
                            <div className="flex flex-row gap-2 items-center min-h-[30px]">
                              <div className="uppercase font-semibold">{milestone.name}</div>
                              {!collapsedProjectIds.includes(p._id) &&
                                !loadingDeliverables &&
                                useNewTaskSelector &&
                                !isLocked && (
                                  <TaskSelector
                                    compact
                                    type="primary"
                                    options={constructOptions(
                                      data?.projects,
                                      selectedDeliverableIds,
                                    )}
                                    onSelect={selected =>
                                      handleClick([selected.project, undefined, selected.task])
                                    }
                                    defaultSelected={{
                                      project: p._id,
                                      category: milestone.name,
                                    }}
                                  />
                                )}
                              {!isLocked && (
                                <ProjectMilestoneBudgetIndicator
                                  projectId={p._id}
                                  milestoneName={milestone.name}
                                  deliverableId={milestone.deliverableId}
                                  milestoneLineItems={lineItems}
                                />
                              )}
                            </div>
                          </td>
                        </tr>
                        {lineItems.map((row, index) => (
                          <TimesheetGridRow
                            className={getClassName(p._id, collapsedProjectIds)}
                            isLocked={isLocked || row.status === 'Approved'}
                            endDate={endDate}
                            key={`${p._id}${row.deliverable._id}`}
                            timesheetId={timesheetId}
                            lineItem={row}
                            lineItemIndex={row.lineItemIndex}
                            user={user}
                          />
                        ))}
                      </React.Fragment>
                    );
                  })}
              </React.Fragment>
            );
          })
        ) : (
          <tr>
            <td colSpan={9}>
              <Empty
                image={Empty.PRESENTED_IMAGE_SIMPLE}
                description="Your tasks for this timesheet will appear here."
              />
            </td>
          </tr>
        )}
        <TimesheetGridRowAddNew
          selectedDeliverableIds={selectedDeliverableIds}
          loading={loadingDeliverables}
          isLocked={isLocked}
          endDate={endDate}
          timesheetId={timesheetId}
          dailyTotals={constructDailyTotals(lineItems)}
          user={user}
          data={data}
          lineItems={lineItems?.length || 0}
          onSubmit={() => {}}
          onSubmitEnd={() => {}}
        />
      </tbody>
    </Table>
  );
};

export default TimesheetGrid;
