import React, { setGlobal } from 'reactn';
import styled from 'styled-components';
import { withApollo } from 'react-apollo';
import moment from 'moment';
import Button from '../../common/components/Button';
import TimesheetGridRowComment from './TimesheetGridRowComment';
import TimesheetGridRowHourContainer from './TimesheetGridRowHourContainer';
import { GET_USER_TIMESHEET } from '../queries';
import Popconfirm from '../../common/components/Popconfirm';
import { REMOVE_TIMESHEET_LINEITEM } from '../mutations';
import { getWeekDays } from '../../common';

const Row = styled.tr`
  &:hover {
    background-color: #f9f9f9;
  }
`;

const Col = styled.td`
  padding: 1em;
  width: ${props => (props.width ? props.width : 'initial')};
  text-align: ${props => (props.align ? props.align : 'initial')};
`;

const TaskTitleContainer = styled.div`
  display: flex;
  flex-direction: column;
`;

const setLoading = (loading, delay) => {
  if (delay) {
    setTimeout(() => {
      setGlobal({ loading });
    }, delay);
  } else {
    setGlobal({ loading });
  }
};

class TimesheetGridRow extends React.Component {
  state = {
    removeLoading: false,
  };

  handleRemoveRow = () => {
    setLoading(true);
    this.setState({ removeLoading: true });
    setTimeout(() => {
      this.props.client
        .mutate({
          mutation: REMOVE_TIMESHEET_LINEITEM,
          awaitRefetchQueries: true,
          refetchQueries: [
            {
              query: GET_USER_TIMESHEET,
              variables: {
                endDate: this.props.endDate,
                user: this.props.user._id,
              },
            },
          ],
          variables: {
            timesheetId: this.props.timesheetId,
            lineItemIndex: this.props.lineItemIndex,
            user: this.props.user._id,
          },
        })
        // componented will be unmounted so no need to call setState
        .then(() => setLoading(false));
    }, 500);
  };

  render() {
    const { timesheetId, lineItem, isLocked, endDate, className } = this.props;
    const { project, deliverable } = lineItem;
    const newTaskRequiredFields = {
      timesheetId,
      projectId: project._id,
      deliverableId: deliverable._id,
    };

    const days = getWeekDays();

    return (
      <Row className={className}>
        <Col>
          <TaskTitleContainer>- {deliverable.deliverableName}</TaskTitleContainer>
        </Col>
        <Col>
          <TimesheetGridRowComment isLocked={isLocked} lineItem={lineItem} user={this.props.user} />
        </Col>
        {days.map((day, index) => {
          const date = moment(endDate)
            .subtract(6 - index, 'days')
            .format('YYYYMMDD');
          const disabled = false; //moment(date).isBefore('2023-09-18', 'day');

          return (
            <Col key={day} align="center">
              <TimesheetGridRowHourContainer
                isRemoving={this.state.removeLoading}
                isLocked={isLocked}
                disabled={disabled}
                user={this.props.user}
                task={lineItem[`${day}Task`]}
                endDate={endDate}
                newTaskRequiredFields={{
                  ...newTaskRequiredFields,
                  date,
                }}
              />
            </Col>
          );
        })}
        {!this.props.isLocked && (
          <Col align="center">
            <Popconfirm
              placement="topLeft"
              title="Are you sure you want to delete this task?"
              onConfirm={this.handleRemoveRow}
              okText="Yes"
              cancelText="No"
            >
              <Button
                loading={this.state.removeLoading}
                disabled={this.global.loading}
                shape="circle"
                icon="close"
                type="danger"
                ghost
              />
            </Popconfirm>
          </Col>
        )}
      </Row>
    );
  }
}

export default withApollo(TimesheetGridRow);
